#about .section-content {
    display: flex;
    height: 100vh;
}

#about .section--inner-content {
    margin: auto;
}

#about h1,
#about h2,
#about h3,
#about h4,
#about h5 {
    margin: 0;
}