body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100vh;
  overflow: hidden;
}

a, a:visited {
  color: #000;
  text-decoration: none;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #333333;
}

#app--main-content-container {
  height: 100vh;
  overflow: scroll;
}

#app--main-content-container.scroll-snap-enabled {
  -webkit-scroll-snap-type: y mandatory;
      -ms-scroll-snap-type: y mandatory;
          scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
}

#app--main-content-container.scroll-snap-enabled section {
  scroll-snap-align: start;
}

#app--main-content-container section {
  min-height: 100vh;
  width: 100vw;
  overflow: visible;

  display: flex;
  justify-content: center;
  align-items: center;
}

#app--main-content-container section .section-content {
  margin: auto;
  max-width: 500px;
  width: 50%;
}

/* Sections */
.app section {
  padding-top: 50px;
}

/* HOVER LINK EFFECT */
.underline-hover-link {
  position: relative;
  display: inline-block;
  outline: none;
  color: #000;
  text-decoration: none;
}

.underline-hover-link:hover,
.underline-hover-link:focus {
  outline: none;
}

.underline-hover-link {
  padding: 0 0 10px;
}

.underline-hover-link::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #36d1d2;
  content: '';
  opacity: 0;
  -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  transition: height 0.3s, opacity 0.3s, transform 0.3s;
  transition: height 0.3s, opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.underline-hover-link:hover::after,
.underline-hover-link:focus::after {
  height: 5px;
  opacity: 1;
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}

/*** SIDEBAR ***/
.sidebar a {
  display: block;
  padding: 6px 8px;
}


footer {
  font-size: 12px;
  padding-top: 45px;
  padding-bottom: 25px;

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#app--main-content-container.scroll-snap-enabled footer {
  scroll-snap-align: end;
}


/* MOBILE STYLES */
@media (max-width: 480px) {

  #app--main-content-container section .section-content {
    width: 70%;
  }

  /* Deactivate snap scroll on mobile */
  #app--main-content-container.scroll-snap-enabled {
    -webkit-scroll-snap-type: none;
        -ms-scroll-snap-type: none;
            scroll-snap-type: none;
  }

  #app--main-content-container.scroll-snap-enabled section {
    scroll-snap-align: none;
  }
}
#about .section-content {
    display: flex;
    height: 100vh;
}

#about .section--inner-content {
    margin: auto;
}

#about h1,
#about h2,
#about h3,
#about h4,
#about h5 {
    margin: 0;
}
.project-card--container {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
                0 1px 1px 0 rgba(0, 0, 0, 0.14),
                0 2px 1px -1px rgba(0, 0, 0, 0.12);
    max-width: 800px;
    margin: auto auto 25px;
}

.project-card--header-container
{
    display: flex;
    padding: 16px;
    align-items: center;
}

.project-card--header-content {
    flex: 1 1 auto;
}

.project-card--header-title {
    margin: 0;
    display: block;
    position: relative;

    color: rgba(0, 0, 0, 0.87);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.35417em;
}

.project-card--header-title a {
    text-decoration: none;
}

.project-card--header-timeframe {
    position: absolute;
    right: 0;
    font-size: 14px;
}

.project-card--sub-header-content {
    margin: 0;
    display: block;
    position: relative;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.46429em;
    color: rgba(0, 0, 0, 0.54);
}

.project-card--technology-list-wrapper {
    display: flex;
    padding: 0 16px 16px;
    align-items: center;
}

@media (max-width: 480px) {
    .project-card--header-timeframe {
        display: block;
        position: relative;
    }
}
.technology-avatar--container {
    width: 40px;
    height: 40px;
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 50%;
    justify-content: center;
    background-color: #bdbdbd;
    color: #616161;
    margin-left: -1px;
    margin-right: -4px;
    font-size: 1rem;
}

.technology-avatar {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 20px;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    flex-shrink: 0;
}

.technology--container {
    color: rgba(0, 0, 0, 0.87);
    cursor: default;
    border: none;
    display: inline-flex;
    outline: none;
    padding: 0;
    -webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-sizing: border-box;
    align-items: center;
    white-space: nowrap;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #e0e0e0;
    height: 40px;
    margin: 8px;
    font-size: 1rem;
    border-radius: 20px;
}

.technology--container span {
    max-width: 0;
    height: 0;
    padding: 0;
    opacity: 0;
    -webkit-transition: max-width 0.7s, opacity 0.5s, padding 0.6s linear;
    transition: max-width 0.7s, opacity 0.5s, padding 0.6s linear;
}

.technology--container:hover span,
.technology--container span:hover {
    max-width: 150px;
    padding: 0 12px;
    opacity: 1;
}

.technology--name {
    cursor: inherit;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
}
.track-list-container
{
    display: none;
    visibility: hidden;
}

.track-list-container.show
{
    display: block;
    visibility: visible;
}

.track-container
{
    padding: 25px;
}
.loading-wrapper
{
    text-align: center;
}

.loading-wrapper img
{
    height: 75px;
    width: 75px;
}
nav {
    background-color: #FFF;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow: hidden;
    padding: 15px;
    height: 65px;
    width: 100%;
    text-align: right;
}

nav a {
    margin: 0 15px;
    font-size: .875rem;
}

nav #resume-button {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px 15px;
    width: 83px;

    -webkit-transition: color 0.5s, background-color 0.5s, -webkit-transform 0.5s;
    transition: color 0.5s, background-color 0.5s, -webkit-transform 0.5s;
    transition: color 0.5s, background-color 0.5s, transform 0.5s;
    transition: color 0.5s, background-color 0.5s, transform 0.5s, -webkit-transform 0.5s;
}

nav #resume-button:hover {
    background: #333333;
    color: #FFF;
}

@media (max-width: 480px) {
    nav a.underline-hover-link {
        display: none;
    }

    nav #resume-button {
        display: block;
        float: right;
    }
}
.sidebar {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 40px;
    overflow-x: hidden;
}

/* The navigation menu links */
.sidebar a {
    display: block;
    padding: 6px 8px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.sidebar .sidebar-icon svg {
    height: 25px;
    width: 25px;
    -webkit-transition: filter 0.3s;
    -webkit-transition: -webkit-filter 0.3s;
    transition: -webkit-filter 0.3s;
    transition: filter 0.3s;
    transition: filter 0.3s, -webkit-filter 0.3s;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

.sidebar .sidebar-icon svg:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
}

@media (max-width: 480px) {
    .sidebar {
        left: 7px;
        padding-top: 15px;
    }
    .sidebar a {
        font-size: 18px;
    }

    .sidebar .sidebar-icon svg {
        height: 20px;
        width: 20px;
    }
}
