.project-card--container {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
                0 1px 1px 0 rgba(0, 0, 0, 0.14),
                0 2px 1px -1px rgba(0, 0, 0, 0.12);
    max-width: 800px;
    margin: auto auto 25px;
}

.project-card--header-container
{
    display: flex;
    padding: 16px;
    align-items: center;
}

.project-card--header-content {
    flex: 1 1 auto;
}

.project-card--header-title {
    margin: 0;
    display: block;
    position: relative;

    color: rgba(0, 0, 0, 0.87);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.35417em;
}

.project-card--header-title a {
    text-decoration: none;
}

.project-card--header-timeframe {
    position: absolute;
    right: 0;
    font-size: 14px;
}

.project-card--sub-header-content {
    margin: 0;
    display: block;
    position: relative;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.46429em;
    color: rgba(0, 0, 0, 0.54);
}

.project-card--technology-list-wrapper {
    display: flex;
    padding: 0 16px 16px;
    align-items: center;
}

@media (max-width: 480px) {
    .project-card--header-timeframe {
        display: block;
        position: relative;
    }
}