.track-list-container
{
    display: none;
    visibility: hidden;
}

.track-list-container.show
{
    display: block;
    visibility: visible;
}

.track-container
{
    padding: 25px;
}