.technology--container {
    color: rgba(0, 0, 0, 0.87);
    cursor: default;
    border: none;
    display: inline-flex;
    outline: none;
    padding: 0;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-sizing: border-box;
    align-items: center;
    white-space: nowrap;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #e0e0e0;
    height: 40px;
    margin: 8px;
    font-size: 1rem;
    border-radius: 20px;
}

.technology--container span {
    max-width: 0;
    height: 0;
    padding: 0;
    opacity: 0;
    -webkit-transition: max-width 0.7s, opacity 0.5s, padding 0.6s linear;
    -moz-transition: max-width 0.7s, opacity 0.5s, padding 0.6s linear;
    transition: max-width 0.7s, opacity 0.5s, padding 0.6s linear;
}

.technology--container:hover span,
.technology--container span:hover {
    max-width: 150px;
    padding: 0 12px;
    opacity: 1;
}

.technology--name {
    cursor: inherit;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
}