.sidebar {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 40px;
    overflow-x: hidden;
}

/* The navigation menu links */
.sidebar a {
    display: block;
    padding: 6px 8px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.sidebar .sidebar-icon svg {
    height: 25px;
    width: 25px;
    -webkit-transition: filter 0.3s;
    -moz-transition: filter 0.3s;
    transition: filter 0.3s;
    filter: grayscale(100%);
}

.sidebar .sidebar-icon svg:hover {
    filter: grayscale(0%);
}

@media (max-width: 480px) {
    .sidebar {
        left: 7px;
        padding-top: 15px;
    }
    .sidebar a {
        font-size: 18px;
    }

    .sidebar .sidebar-icon svg {
        height: 20px;
        width: 20px;
    }
}