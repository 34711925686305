.technology-avatar--container {
    width: 40px;
    height: 40px;
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    background-color: #bdbdbd;
    color: #616161;
    margin-left: -1px;
    margin-right: -4px;
    font-size: 1rem;
}

.technology-avatar {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 20px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
}
