nav {
    background-color: #FFF;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow: hidden;
    padding: 15px;
    height: 65px;
    width: 100%;
    text-align: right;
}

nav a {
    margin: 0 15px;
    font-size: .875rem;
}

nav #resume-button {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px 15px;
    width: 83px;

    -webkit-transition: color 0.5s, background-color 0.5s, -webkit-transform 0.5s;
    -moz-transition: color 0.5s, background-color 0.5s, -moz-transform 0.5s;
    transition: color 0.5s, background-color 0.5s, transform 0.5s;
}

nav #resume-button:hover {
    background: #333333;
    color: #FFF;
}

@media (max-width: 480px) {
    nav a.underline-hover-link {
        display: none;
    }

    nav #resume-button {
        display: block;
        float: right;
    }
}