body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100vh;
  overflow: hidden;
}

a, a:visited {
  color: #000;
  text-decoration: none;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #333333;
}

#app--main-content-container {
  height: 100vh;
  overflow: scroll;
}

#app--main-content-container.scroll-snap-enabled {
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
}

#app--main-content-container.scroll-snap-enabled section {
  scroll-snap-align: start;
}

#app--main-content-container section {
  min-height: 100vh;
  width: 100vw;
  overflow: visible;

  display: flex;
  justify-content: center;
  align-items: center;
}

#app--main-content-container section .section-content {
  margin: auto;
  max-width: 500px;
  width: 50%;
}

/* Sections */
.app section {
  padding-top: 50px;
}

/* HOVER LINK EFFECT */
.underline-hover-link {
  position: relative;
  display: inline-block;
  outline: none;
  color: #000;
  text-decoration: none;
}

.underline-hover-link:hover,
.underline-hover-link:focus {
  outline: none;
}

.underline-hover-link {
  padding: 0 0 10px;
}

.underline-hover-link::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #36d1d2;
  content: '';
  opacity: 0;
  -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
  transition: height 0.3s, opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}

.underline-hover-link:hover::after,
.underline-hover-link:focus::after {
  height: 5px;
  opacity: 1;
  -webkit-transform: translateY(-7px);
  -moz-transform: translateY(-7px);
  transform: translateY(-7px);
}

/*** SIDEBAR ***/
.sidebar a {
  display: block;
  padding: 6px 8px;
}


footer {
  font-size: 12px;
  padding-top: 45px;
  padding-bottom: 25px;

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#app--main-content-container.scroll-snap-enabled footer {
  scroll-snap-align: end;
}


/* MOBILE STYLES */
@media (max-width: 480px) {

  #app--main-content-container section .section-content {
    width: 70%;
  }

  /* Deactivate snap scroll on mobile */
  #app--main-content-container.scroll-snap-enabled {
    scroll-snap-type: none;
  }

  #app--main-content-container.scroll-snap-enabled section {
    scroll-snap-align: none;
  }
}